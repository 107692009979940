export const categories = [
  "Student-Based Revenue",
  "Facility-Based Revenue",
  "Service-Based Revenue",
  "Community and External Revenue",
  "Financial Investments",
  // "Library-Based Revenue",
  // "Penalties",
  // //"Other",
];

export const subCategories = {
  "Student-Based Revenue": [
    "Tuition Fees",
    "Transport Fees",
    "Hostel Fees",
    "Exam Fees",
    "Event Fees",
    "Certificate Fees",
    "Meal Fees",
    "Application Fees",
    //"Other",
  ],
  "Facility-Based Revenue": [
    "Rent Income",
    "Exam Center Fees",
    "Parking Fees",
    //"Other",
  ],
  "Service-Based Revenue": [
    "Stationery Fees",
    //"Other Facility Fees",
    "Subscription Fees",
    "Workshop/Training Fees",
    "Canteen Profit",
    //"Other",
  ],
  "Community and External Revenue": [
    "Donations",
    "Fundraising/Sponsorships",
    //"Other",
  ],
  "Financial Investments": ["Investments"],
  // "Library-Based Revenue": ["Library Fees", "Membership Fees", //"Other"],
  // Penalties: ["Late Fees", "Fines", //"Other"],
  // Other: [//"Other"],
};

export const academicYears = [
  { value: "2023-2024", label: "2023-2024" },
  { value: "2024-2025", label: "2024-2025" },
  { value: "2025-2026", label: "2025-2026" },
  // Add more academic years as needed
];
